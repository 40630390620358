import { send } from "emailjs-com";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import useNav from "../hooks/useNav";
import './Contact.scss';

const initialValue = {
    name: '',
    mail: '',
    message: '',
}

function Contact() {
    const [toSend, setToSend] = useState(initialValue);
    const [count, setCount] = useState(0);
    const [nameError, setNameError] = useState('');
    const [mailError, setMailError] = useState('');
    const [messageError, setMessageError] = useState('');
    const { enqueueSnackbar} = useSnackbar();

    const contactRef = useNav("Contact");

    function isValidEmail(email: string) {
        // eslint-disable-next-line
        return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);
    }

    const handleChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (e.currentTarget.name === 'mail') {
            if (isValidEmail(e.currentTarget.value))
                setMailError('');
        }
        else if (e.currentTarget.name === 'name') {
            if (e.currentTarget.value.length >= 3)
                setNameError('');
        }
        else if (e.currentTarget.name === 'message') {
            if (e.currentTarget.value.length >= 50)
                setMessageError('');
        }
        setToSend({ ...toSend, [e.currentTarget.name]: e.currentTarget.value });
    }

    const onSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        const validEmail = isValidEmail(toSend.mail);
        const lengthName = toSend.name.length < 3;
        const lengthMessage = toSend.message.length < 50;
        if (!validEmail) {
            setMailError('err')
            enqueueSnackbar('Email Invalide !');
        }
        if (lengthName) {
            setNameError('err');
            enqueueSnackbar('Votre nom est trop court ! (minimum 3 caratères)');
        }
        if (lengthMessage) {
            setMessageError('err');
            enqueueSnackbar('Votre message est trop court ! (minimum 50 caractères)');
        }
        if (validEmail && !lengthName && !lengthMessage) {
            send(
                'service_i3vvk0v',
                'template_uxk819j',
                toSend,
                'AIu-wvoCLFzBIOL7b'
            )
                .then(() => {
                    enqueueSnackbar('Le message a bien été envoyé !', { variant: 'success' });
                    setToSend(initialValue);
                    setCount(0);
                })
                .catch(() => {
                    enqueueSnackbar('Le message n\'a pas réussi à être envoyé !');
                });
        }
    }

    return (
        <section ref={contactRef} className="contact" id="contact">
                    <div className="title">
                        <h2>Me Contacter</h2>
                        <div className="divider"></div>
                    </div>
            <div className="container-contact">
                <div className="form-title">
                    <form onSubmit={onSubmit}>
                        <div className="name-mail">
                            <input
                                type='text'
                                name="name"
                                placeholder="Nom"
                                value={toSend.name}
                                className={nameError}
                                onChange={handleChange}
                            />
                            <input
                                type='text'
                                name="mail"
                                placeholder="E-mail"
                                value={toSend.mail}
                                onChange={handleChange}
                                className={mailError}
                            />
                        </div>
                        <textarea
                            name="message"
                            maxLength={2000}
                            placeholder="Vos besoins."
                            value={toSend.message}
                            className={messageError}
                            onChange={(e) => { handleChange(e); setCount(e.target.value.length) }}
                        />
                        <p>{count} / 2000</p>
                        <button className="btn" type="submit">Envoyer</button>
                    </form>
                </div>
                <div className="container-undraw">
                    <svg className="undraw-contact" viewBox="0 0 635 585" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_106_37)">
                            <path d="M291.294 321.502C291.181 321.502 291.11 321.5 291.085 321.498L277.52 321.5V319.5H291.134C291.449 319.511 297.88 319.692 303.805 312.503C312.634 301.788 319.885 275.263 304.286 209.747C283.311 121.651 300.805 43.8237 309.3 14.4517C309.719 12.9985 309.793 11.4679 309.519 9.98086C309.244 8.49378 308.627 7.09098 307.717 5.88326C306.807 4.67554 305.629 3.696 304.275 3.02205C302.921 2.34811 301.429 1.99821 299.917 2.00001H97.3416C95.8014 2.00207 94.2835 2.36725 92.9109 3.06589C91.5384 3.76454 90.3499 4.77698 89.4419 6.02102C85.8433 10.9712 80.9048 20.481 77.8145 37.6763L75.8462 37.3228C79.0034 19.7539 84.1025 9.96438 87.8242 4.84524C88.9179 3.34623 90.3498 2.12626 92.0034 1.2844C93.6571 0.442535 95.4859 0.00249081 97.3415 5.44202e-06H299.917C301.739 -0.00174809 303.537 0.420291 305.168 1.23278C306.799 2.04527 308.219 3.22594 309.316 4.68152C310.412 6.13711 311.155 7.82772 311.486 9.61979C311.817 11.4119 311.726 13.2563 311.221 15.0073C302.77 44.2271 285.367 121.651 306.232 209.284C322.05 275.722 314.446 302.821 305.281 313.856C299.236 321.134 292.576 321.502 291.294 321.502Z" fill="#E6E6E6" />
                            <path d="M254.794 358.002C254.681 358.002 254.61 358 254.585 357.998L241.02 358V356H254.634C254.951 356.009 261.38 356.192 267.305 349.003C276.134 338.288 283.385 311.763 267.786 246.247C246.811 158.151 264.305 80.3237 272.8 50.9517C273.219 49.4985 273.294 47.9679 273.019 46.4808C272.744 44.9938 272.127 43.591 271.217 42.3833C270.307 41.1755 269.129 40.196 267.775 39.522C266.421 38.8481 264.929 38.4982 263.417 38.5H60.8417C59.3015 38.5021 57.7836 38.8672 56.411 39.5659C55.0385 40.2645 53.85 41.277 52.942 42.521C49.3434 47.4712 44.4049 56.981 41.3147 74.1763L39.3462 73.8228C42.5035 56.2539 47.6027 46.4644 51.3243 41.3452C52.4181 39.8462 53.8499 38.6263 55.5035 37.7844C57.1572 36.9425 58.9861 36.5025 60.8417 36.5H263.417C265.239 36.4982 267.037 36.9203 268.668 37.7328C270.3 38.5453 271.719 39.7259 272.816 41.1815C273.912 42.6371 274.655 44.3277 274.986 46.1198C275.317 47.9118 275.226 49.7563 274.722 51.5073C266.27 80.7271 248.867 158.151 269.732 245.784C285.55 312.222 277.946 339.321 268.781 350.356C262.736 357.634 256.076 358.002 254.794 358.002Z" fill="#CCCCCC" />
                            <path d="M218.294 394.502C218.181 394.502 218.11 394.5 218.085 394.498L18.6315 394.5C16.7113 394.502 14.8197 394.033 13.1224 393.135C11.425 392.237 9.97365 390.937 8.89528 389.348C7.8169 387.759 7.14439 385.93 6.93657 384.021C6.72875 382.112 6.99197 380.181 7.7032 378.397C14.94 360.317 24.684 316.938 8.63472 229.558C-10.3167 126.378 6.64986 89.0884 14.824 77.8452C15.9178 76.3462 17.3497 75.1262 19.0034 74.2844C20.6571 73.4425 22.486 73.0025 24.3416 73H226.917C228.739 72.9982 230.537 73.4203 232.168 74.2328C233.799 75.0453 235.219 76.2259 236.316 77.6815C237.412 79.1371 238.155 80.8277 238.486 82.6198C238.817 84.4118 238.726 86.2563 238.221 88.0073C229.77 117.227 212.367 194.651 233.232 282.284C249.05 348.722 241.446 375.821 232.281 386.856C226.236 394.134 219.576 394.502 218.294 394.502ZM24.3416 75C22.8015 75.002 21.2835 75.3672 19.9109 76.0659C18.5383 76.7645 17.3497 77.777 16.4417 79.021C8.41914 90.0557 -8.20976 126.778 10.6017 229.197C25.9336 312.67 18.4977 356.811 9.55986 379.141C8.95749 380.614 8.73139 382.214 8.90196 383.796C9.07254 385.379 9.63442 386.894 10.5369 388.205C11.4318 389.529 12.6378 390.612 14.0491 391.361C15.4604 392.11 17.0339 392.501 18.6315 392.5H218.134C218.449 392.515 224.88 392.693 230.805 385.503C239.634 374.788 246.885 348.263 231.286 282.747C210.311 194.651 227.805 116.824 236.3 87.4517C236.719 85.9985 236.793 84.4679 236.519 82.9808C236.244 81.4938 235.627 80.091 234.717 78.8833C233.807 77.6755 232.629 76.696 231.275 76.022C229.921 75.3481 228.429 74.9982 226.917 75H24.3416Z" fill="#3F3D56" />
                            <path d="M192.461 163H33.4609C31.3392 163 29.3044 162.157 27.8041 160.657C26.3038 159.157 25.4609 157.122 25.4609 155C25.4609 152.878 26.3038 150.843 27.8041 149.343C29.3044 147.843 31.3392 147 33.4609 147H192.461C194.583 147 196.618 147.843 198.118 149.343C199.618 150.843 200.461 152.878 200.461 155C200.461 157.122 199.618 159.157 198.118 160.657C196.618 162.157 194.583 163 192.461 163Z" fill="#B2967D" />
                            <path d="M192.461 197H33.4609C31.3392 197 29.3044 196.157 27.8041 194.657C26.3038 193.157 25.4609 191.122 25.4609 189C25.4609 186.878 26.3038 184.843 27.8041 183.343C29.3044 181.843 31.3392 181 33.4609 181H192.461C194.583 181 196.618 181.843 198.118 183.343C199.618 184.843 200.461 186.878 200.461 189C200.461 191.122 199.618 193.157 198.118 194.657C196.618 196.157 194.583 197 192.461 197Z" fill="#CCCCCC" />
                            <path d="M192.461 231H33.4609C31.3392 231 29.3044 230.157 27.8041 228.657C26.3038 227.157 25.4609 225.122 25.4609 223C25.4609 220.878 26.3038 218.843 27.8041 217.343C29.3044 215.843 31.3392 215 33.4609 215H192.461C194.583 215 196.618 215.843 198.118 217.343C199.618 218.843 200.461 220.878 200.461 223C200.461 225.122 199.618 227.157 198.118 228.657C196.618 230.157 194.583 231 192.461 231Z" fill="#CCCCCC" />
                            <path d="M204.461 265H45.4609C43.3392 265 41.3044 264.157 39.8041 262.657C38.3038 261.157 37.4609 259.122 37.4609 257C37.4609 254.878 38.3038 252.843 39.8041 251.343C41.3044 249.843 43.3392 249 45.4609 249H204.461C206.583 249 208.618 249.843 210.118 251.343C211.618 252.843 212.461 254.878 212.461 257C212.461 259.122 211.618 261.157 210.118 262.657C208.618 264.157 206.583 265 204.461 265Z" fill="#CCCCCC" />
                            <path d="M204.461 299H45.4609C43.3392 299 41.3044 298.157 39.8041 296.657C38.3038 295.157 37.4609 293.122 37.4609 291C37.4609 288.878 38.3038 286.843 39.8041 285.343C41.3044 283.843 43.3392 283 45.4609 283H204.461C206.583 283 208.618 283.843 210.118 285.343C211.618 286.843 212.461 288.878 212.461 291C212.461 293.122 211.618 295.157 210.118 296.657C208.618 298.157 206.583 299 204.461 299Z" fill="#CCCCCC" />
                            <path d="M108.461 129H33.4609C31.3392 129 29.3044 128.157 27.8041 126.657C26.3038 125.157 25.4609 123.122 25.4609 121C25.4609 118.878 26.3038 116.843 27.8041 115.343C29.3044 113.843 31.3392 113 33.4609 113H108.461C110.583 113 112.618 113.843 114.118 115.343C115.618 116.843 116.461 118.878 116.461 121C116.461 123.122 115.618 125.157 114.118 126.657C112.618 128.157 110.583 129 108.461 129Z" fill="#B2967D" />
                            <path d="M98.7767 353.822C100.391 346.987 107.975 344.746 114.175 344.997C118.092 345.261 121.978 345.876 125.786 346.834C129.77 347.725 133.715 348.781 137.641 349.9C144.839 351.953 151.964 354.436 159.281 356.041C165.372 357.377 171.992 358.161 178.067 356.316C183.906 354.543 188.86 350.19 190.449 344.172C191.946 338.5 189.89 332.299 184.395 329.707C179.383 327.343 173.052 328.545 170.568 333.89C167.767 339.917 170.566 346.49 175.001 350.851C177.521 353.218 180.282 355.315 183.238 357.108C186.239 359.153 189.579 360.648 193.102 361.526C198.887 362.761 205.778 360.941 208.762 355.414C209.425 354.163 209.824 352.789 209.933 351.377C210.11 349.455 207.109 349.467 206.933 351.377C206.428 356.88 199.983 359.458 195.163 358.854C191.91 358.315 188.803 357.109 186.038 355.312C183.369 353.756 180.84 351.972 178.48 349.979C174.392 346.442 170.56 340.62 173.305 335.1C175.541 330.605 181.368 330.65 184.913 333.549C189.206 337.059 188.44 343.431 185.609 347.6C182.474 352.217 176.792 354.152 171.435 354.361C165.614 354.587 159.842 353.252 154.278 351.672C147.366 349.709 140.522 347.512 133.576 345.664C129.633 344.615 125.659 343.668 121.645 342.931C117.849 342.095 113.95 341.825 110.075 342.13C103.815 342.853 97.4254 346.497 95.884 353.024C95.4406 354.902 98.3325 355.703 98.7768 353.822L98.7767 353.822Z" fill="#3F3D56" />
                            <path d="M405.276 523.44C405.449 543.131 406.891 562.79 409.594 582.295C409.689 582.98 409.792 583.665 409.887 584.35L594.289 583.319C594.831 582.639 595.364 581.951 595.88 581.254C597.602 578.948 599.215 576.577 600.696 574.124C607.816 562.307 611.075 548.472 607.809 538.926L607.751 538.777C607.046 536.687 605.934 534.757 604.481 533.097C597.034 524.714 581.977 526.112 568.314 532.878C580.569 519.463 590.328 502.535 591.069 487.425C591.796 472.687 584.09 462.158 575.731 453.211C575.457 452.915 575.183 452.627 574.908 452.34C574.775 452.192 574.634 452.052 574.502 451.904C567.98 445.086 560.377 438.364 549.372 439.177C537.303 440.071 523.921 450.18 514.539 461.96C505.156 473.732 498.921 487.106 492.602 500.157C486.274 513.201 479.427 526.577 469.273 537.726C483.026 520.33 494.115 500.537 498.211 481.287C502.308 462.037 498.703 443.549 486.445 434.483C482.724 431.815 478.418 430.078 473.888 429.415C473.359 429.328 472.822 429.256 472.276 429.193C460.929 427.902 447.381 431.827 435.854 441.207C423.16 451.536 414.359 466.848 409.98 481.384C405.602 495.919 405.135 509.913 405.276 523.44Z" fill="#F2F2F2" />
                            <path d="M525.923 583.701L529.449 583.682C530.569 582.982 531.693 582.29 532.824 581.606C533.523 581.165 534.231 580.74 534.939 580.306C546.635 573.163 558.405 566.311 570.247 559.75C582.079 553.184 593.97 546.921 605.921 540.96C606.238 540.822 606.496 540.577 606.651 540.269C606.806 539.96 606.849 539.607 606.77 539.27C606.758 539.226 606.738 539.184 606.712 539.147C606.545 538.834 606.172 538.704 605.629 538.971C604.163 539.706 602.688 540.441 601.223 541.192C589.239 547.255 577.307 553.621 565.427 560.289C553.555 566.955 541.75 573.914 530.013 581.168C529.766 581.318 529.519 581.476 529.273 581.626C528.153 582.318 527.042 583.01 525.923 583.701Z" fill="white" />
                            <path d="M480.458 583.955L482.638 583.943C483.13 583.255 483.614 582.567 484.105 581.879C495.66 565.676 507.212 549.473 518.761 533.27C537.675 506.744 556.583 480.217 575.486 453.691C575.598 453.548 575.681 453.385 575.731 453.211C575.893 452.623 575.461 452.279 574.908 452.34C574.675 452.373 574.452 452.456 574.253 452.582C574.055 452.709 573.885 452.876 573.756 453.073C563.152 467.949 552.551 482.82 541.952 497.685C523.137 524.078 504.324 550.469 485.514 576.857C484.318 578.532 483.121 580.216 481.925 581.891C481.433 582.579 480.941 583.267 480.458 583.955Z" fill="white" />
                            <path d="M431.882 571.293C431.993 574.968 432.236 578.584 432.554 582.167C432.615 582.852 432.677 583.537 432.739 584.222L435.027 584.209C434.957 583.524 434.887 582.839 434.825 582.154C434.342 576.978 433.999 571.744 433.952 566.367C433.891 551.462 435.344 536.588 438.288 521.976C441.417 506.242 445.91 490.811 451.715 475.856C457.809 460.084 465.156 444.825 473.685 430.226C473.846 429.988 473.918 429.701 473.887 429.416C473.793 428.764 472.925 428.57 472.276 429.193C472.148 429.316 472.037 429.455 471.948 429.608C470.893 431.423 469.856 433.243 468.84 435.068C460.625 449.748 453.593 465.06 447.812 480.858C442.305 495.862 438.117 511.316 435.293 527.047C432.649 541.641 431.506 556.467 431.882 571.293V571.293Z" fill="white" />
                            <path d="M209.005 354.533C208.625 354.217 208.348 353.796 208.208 353.323C208.067 352.849 208.07 352.345 208.216 351.874C208.362 351.402 208.644 350.984 209.026 350.673C209.409 350.361 209.875 350.17 210.367 350.123C215.821 349.596 221.326 350.16 226.56 351.783L279.875 368.248C280.565 368.461 281.206 368.808 281.762 369.269C282.318 369.73 282.778 370.296 283.115 370.935C283.453 371.574 283.661 372.273 283.728 372.992C283.795 373.711 283.72 374.436 283.507 375.126C283.294 375.816 282.947 376.458 282.486 377.014C282.025 377.57 281.459 378.03 280.82 378.367C280.182 378.704 279.483 378.912 278.764 378.979C278.044 379.047 277.319 378.972 276.629 378.758L223.314 362.293C218.076 360.681 213.212 358.043 209.005 354.533V354.533Z" fill="#3F3D56" />
                            <path d="M427.552 422.932C426.104 422.325 424.806 421.407 423.751 420.244C422.696 419.081 421.908 417.7 421.444 416.2C420.98 414.7 420.85 413.116 421.065 411.56C421.279 410.004 421.832 408.514 422.684 407.195L413.545 359.404L436.837 357.588L438.243 404.335C440.364 405.913 441.832 408.215 442.369 410.804C442.907 413.393 442.476 416.09 441.158 418.382C439.84 420.674 437.727 422.404 435.219 423.242C432.712 424.081 429.984 423.971 427.552 422.932Z" fill="#FFB8B8" />
                            <path d="M412.108 398.267L388.385 287.784C387.411 283.246 388.279 278.508 390.799 274.61C393.319 270.712 397.284 267.975 401.822 267.001C406.359 266.027 411.098 266.895 414.996 269.415C418.894 271.935 421.631 275.9 422.605 280.438L446.328 390.919C446.577 392.086 446.353 393.304 445.706 394.306C445.058 395.308 444.039 396.012 442.873 396.264L417.452 401.722C416.875 401.846 416.278 401.855 415.697 401.749C415.115 401.643 414.561 401.423 414.064 401.102C413.568 400.781 413.14 400.365 412.804 399.879C412.468 399.392 412.232 398.845 412.108 398.267V398.267Z" fill="#B2967D" />
                            <path d="M373.17 572.064H360.911L355.078 524.776L373.173 524.777L373.17 572.064Z" fill="#FFB8B8" />
                            <path d="M352.153 568.561H375.797V583.448H337.267C337.267 581.493 337.652 579.557 338.4 577.751C339.148 575.944 340.244 574.303 341.627 572.921C343.009 571.539 344.65 570.442 346.457 569.694C348.263 568.946 350.199 568.561 352.153 568.561V568.561Z" fill="#2F2E41" />
                            <path d="M414.17 571.064H401.911L396.078 523.776L414.173 523.777L414.17 571.064Z" fill="#FFB8B8" />
                            <path d="M393.153 567.561H416.797V582.448H378.267C378.267 580.493 378.652 578.557 379.4 576.751C380.148 574.944 381.244 573.303 382.627 571.921C384.009 570.539 385.65 569.442 387.457 568.694C389.263 567.946 391.199 567.561 393.153 567.561V567.561Z" fill="#2F2E41" />
                            <path d="M358.481 563.243C358.022 562.866 357.642 562.401 357.365 561.875C357.087 561.349 356.917 560.773 356.865 560.181L342.613 403.401C342.557 402.793 342.627 402.179 342.816 401.599C343.006 401.018 343.312 400.481 343.715 400.023C344.119 399.564 344.612 399.192 345.163 398.929C345.715 398.667 346.315 398.52 346.926 398.497L418.405 395.817C419.024 395.794 419.641 395.898 420.217 396.124C420.793 396.351 421.317 396.693 421.754 397.131C422.192 397.568 422.535 398.091 422.762 398.667C422.988 399.243 423.094 399.86 423.071 400.479L417.357 556.655C417.315 557.749 416.877 558.789 416.125 559.583C415.373 560.378 414.357 560.871 413.268 560.972L398.855 562.282C398.257 562.337 397.655 562.271 397.083 562.089C396.511 561.907 395.981 561.613 395.525 561.223C395.069 560.833 394.695 560.356 394.426 559.82C394.157 559.283 393.997 558.699 393.958 558.1L387.343 458.883C387.338 458.692 387.296 458.504 387.218 458.331C387.14 458.157 387.028 458 386.889 457.87C386.749 457.739 386.586 457.638 386.407 457.572C386.228 457.505 386.038 457.476 385.847 457.484H385.841C385.65 457.476 385.459 457.507 385.28 457.574C385.101 457.641 384.937 457.744 384.798 457.875C384.659 458.007 384.548 458.164 384.471 458.34C384.393 458.515 384.352 458.703 384.349 458.895L378.43 558.78C378.37 559.85 377.93 560.863 377.188 561.635C376.446 562.408 375.452 562.889 374.385 562.991L361.795 564.251C361.203 564.312 360.605 564.254 360.036 564.081C359.468 563.908 358.939 563.623 358.482 563.243H358.481Z" fill="#2F2E41" />
                            <path d="M385.977 243.523C399.542 243.523 410.538 232.527 410.538 218.962C410.538 205.398 399.542 194.401 385.977 194.401C372.412 194.401 361.416 205.398 361.416 218.962C361.416 232.527 372.412 243.523 385.977 243.523Z" fill="#FFB8B8" />
                            <path d="M338.679 404.463C338.241 403.966 337.92 403.379 337.738 402.742C337.556 402.106 337.518 401.437 337.626 400.784C342.16 373.223 339.605 332.71 336.662 303.577C335.894 295.849 337.176 288.055 340.378 280.98C343.58 273.905 348.59 267.799 354.903 263.276L367.73 252.281C368.583 251.559 369.67 251.174 370.787 251.199L402.654 252.11C403.803 252.141 404.896 252.613 405.707 253.427L410.631 264.331L410.682 264.368C416.55 268.681 421.257 274.381 424.383 280.959C427.51 287.536 428.957 294.785 428.597 302.059L423.906 398.995C423.846 400.122 423.367 401.186 422.563 401.978C421.759 402.77 420.687 403.233 419.559 403.276L342.212 405.993C342.162 405.994 342.112 405.995 342.063 405.995C341.422 405.996 340.788 405.86 340.205 405.596C339.621 405.331 339.101 404.945 338.679 404.463V404.463Z" fill="#B2967D" />
                            <path d="M245.903 364.012C245.864 362.442 246.17 360.883 246.8 359.444C247.429 358.005 248.366 356.721 249.545 355.684C250.724 354.646 252.116 353.88 253.623 353.439C255.131 352.997 256.716 352.892 258.269 353.129L298.134 325.231L309.323 345.739L267.247 366.155C266.674 368.736 265.175 371.018 263.033 372.568C260.89 374.118 258.254 374.828 255.623 374.564C252.992 374.3 250.549 373.08 248.758 371.135C246.966 369.19 245.95 366.656 245.903 364.012L245.903 364.012Z" fill="#FFB8B8" />
                            <path d="M279.451 368.861C278.867 368.773 278.306 368.57 277.8 368.264C277.295 367.957 276.855 367.554 276.506 367.076L261.122 346.116C260.417 345.154 260.123 343.951 260.304 342.771C260.484 341.592 261.126 340.533 262.086 339.825L353.181 272.962C356.923 270.216 361.602 269.068 366.19 269.772C368.461 270.121 370.642 270.913 372.607 272.104C374.573 273.296 376.284 274.862 377.644 276.715C379.004 278.568 379.986 280.67 380.533 282.902C381.08 285.134 381.183 287.452 380.834 289.724C380.486 291.995 379.693 294.176 378.502 296.141C377.311 298.107 375.744 299.818 373.891 301.178L282.796 368.041C282.028 368.608 281.098 368.914 280.144 368.914C279.912 368.914 279.68 368.896 279.451 368.861V368.861Z" fill="#B2967D" />
                            <path d="M387.234 240.827C390.491 236.833 390.135 230.75 387.521 226.309C384.906 221.868 380.469 218.809 375.902 216.42C371.336 214.031 366.485 212.152 362.188 209.307C357.891 206.463 358.078 194.42 356.934 189.395L359.663 190.304C358.614 188.962 358.278 184.997 357.229 183.655C360.313 183.96 362.684 186.888 365.767 187.193L363.988 182.416C374.562 181.764 381.137 189.112 391.711 188.46C396.95 188.137 402.64 187.933 406.947 190.933C410.69 193.541 412.585 198.054 413.828 202.443C416.497 211.866 416.912 222.421 412.302 231.062C407.691 239.703 397.065 245.526 387.781 242.406L387.234 240.827Z" fill="#2F2E41" />
                            <path d="M252.387 584H633.387C633.652 584 633.907 583.895 634.094 583.707C634.282 583.52 634.387 583.265 634.387 583C634.387 582.735 634.282 582.48 634.094 582.293C633.907 582.105 633.652 582 633.387 582H252.387C252.122 582 251.868 582.105 251.68 582.293C251.493 582.48 251.387 582.735 251.387 583C251.387 583.265 251.493 583.52 251.68 583.707C251.868 583.895 252.122 584 252.387 584Z" fill="#3F3D56" />
                        </g>
                        <defs>
                            <clipPath id="clip0_106_37">
                                <rect width="634.387" height="584.35" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            </div>
        </section>
    )
}

export default Contact;